<template>
  <div class="box modal__item">
    <div class="search-box">
      <span class="search-box__icon">
        <toko-search-icon />
      </span>
      <input
        type="text"
        class="form-control search-box__input is-expand"
        placeholder="Check ID Card"
        autofocus
        v-model="searchText"
        @keydown.enter="onCheck"
      />
    </div>
    <i class="text-purple">Press enter to check!</i>

    <div v-if="existsUserDocuments && existsUserDocuments.total > 0">
      <p class="mt-16 text-bold">Total: {{ existsUserDocuments.total }}</p>

      <div class="table--scroller-y" :style="{ maxHeight: cardFormHeight + 'px' }">
        <table class="table table--striped">
          <thead>
            <tr>
              <th>Acc. ID</th>
              <th>Doc. Code</th>
            </tr>
          </thead>

          <tbody>
            <tr
              v-for="user in existsUserDocuments.identity_documents"
              :key="user.id"
              class="link--clone"
              @click="$router.open({ name: 'User', params: { id: user.user_id } })"
            >
              <td>{{ user.user_id }}</td>
              <td>{{ user.document_code }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="text-center mt-12" v-if="existsUserDocuments && existsUserDocuments.total === 0">
      <p class="headline--md">Document is Available</p>

      <button class="btn btn--primary mt-12" @click="saveDocument">Save</button>
    </div>
  </div>
</template>

<script>
const TokoSearchIcon = () => import('@/ui/toko-icons/TokoSearch')

export default {
  name: 'DocumentCheck',

  data() {
    return {
      searchText: ''
    }
  },

  props: {
    existsUserDocuments: {
      type: Object,
      require: true
    },

    cardFormHeight: {
      type: Number,
      require: true
    },

    code: {
      type: String,
      default: ''
    }
  },

  components: {
    TokoSearchIcon
  },

  methods: {
    onCheck() {
      if (!this.searchText) return alert('Please input document id!')
      this.$emit('on-check', this.searchText)
    },

    saveDocument() {
      this.$emit('save-document', this.searchText)
    }
  },

  created() {
    this.searchText = this.code
  }
}
</script>
